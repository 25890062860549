.gif-container {
  position: relative;
  width: 100%;
  height: 100%;
  .gif {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-animation: fadeslideUp 12s infinite; /* Chrome, Opera 15+, Safari 5+ */
    -moz-animation: fadeslideUp 12s infinite; /* Fx 5+ */
    -o-animation: fadeslideUp 12s infinite; /* Opera 12+ */
    animation: fadeslideUp 12s infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  }
  .g2 {
    animation-delay: -6s;
    -webkit-animation-delay: -6s;
  }
}

@-webkit-keyframes fadeslideUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40%);
  }
  10% {
    opacity: 1;
    -webkit-transform: translateY(-50%);
  }
  45% {
    opacity: 1;
    -webkit-transform: translateY(-50%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-60%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-60%);
  }
}

@-moz-keyframes fadeslideUp {
  0% {
    opacity: 0;
    transform: translateY(-40%);
  }
  10% {
    opacity: 1;
    transform: translateY(-50%);
  }
  45% {
    opacity: 1;
    transform: translateY(-50%);
  }
  50% {
    opacity: 0;
    transform: translateY(-60%);
  }
  100% {
    opacity: 0;
    transform: translateY(-60%);
  }
}

@-o-keyframes fadeslideUp {
  0% {
    opacity: 0;
    transform: translateY(-40%);
  }
  10% {
    opacity: 1;
    transform: translateY(-50%);
  }
  45% {
    opacity: 1;
    transform: translateY(-50%);
  }
  50% {
    opacity: 0;
    transform: translateY(-60%);
  }
  100% {
    opacity: 0;
    transform: translateY(-60%);
  }
}

@keyframes fadeslideUp {
  0% {
    opacity: 0;
    transform: translateY(-40%);
    -ms-transform: translateY(-40%);
  }
  10% {
    opacity: 1;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
  }
  45% {
    opacity: 1;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
  }
  50% {
    opacity: 0;
    transform: translateY(-60%);
    -ms-transform: translateY(-60%);
  }
  100% {
    opacity: 0;
    transform: translateY(-60%);
    -ms-transform: translateY(-60%);
  }
}
