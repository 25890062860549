@tailwind base;

@layer base {
  h2 {
    @apply text-3xl;
  }
  h3 {
    @apply text-2xl;
  }
  h4 {
    @apply text-xl;
  }
}

@tailwind components;
@tailwind utilities;
