.page-container {
  color: #d3d3d4;
  max-width: 1120px !important;
}

.rounded-box {
  background-color: #23232a;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  position: relative;
  padding: 28px;
}

.MuiTab-textColorPrimary {
  color: #d3d3d4;
}
