.numberInput {
  height: 44px;
  background: #191b1e;
  border-radius: 8px;
}

.numberInputHasError {
  border: 1px solid #f53c5b;
}

.buttonMax {
  width: 76px;
  height: 100%;
  background: #36363f;
  box-shadow: 0px 0px 1px rgba(13, 13, 13, 0.9), 0px 1px 3px #0d0d0d;
  border-radius: 8px;
  font-size: 14px;
}
