.faqs-component {
  .MuiAccordionSummary-root {
    padding: 16px 32px;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0 !important;
  }
  .MuiPaper-root.Mui-expanded {
    margin: 0 !important;
  }
}
