.MuiFormControl-root {
  > label {
    margin-bottom: 4px;
    font-size: 14px;
  }
  .MuiFormHelperText-root {
    margin: 0px;
    font-size: 14px;
  }
}

.MuiOutlinedInput-input {
  border-radius: 10px;
  border: 1px solid transparent !important;
}

.MuiTextField-root {
  border-radius: 9px;
  .Mui-disabled {
    width: 100%;

    background-color: transparent;
    border-radius: 1px;
  }
}

.MuiFormControl-root {
  .Mui-disabled {
    background-color: #3c3c3e !important;

    border-radius: 10px;
    border: none;
  }
}

.MuiOutlinedInput-notchedOutline > legend {
  width: unset;
}

.MuiOutlinedInput-root {
  .Mui-disabled {
    background-color: #3c3c3c;
    opacity: 10 !important;
    -webkit-text-fill-color: rgba(182, 182, 190, 1) !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
}

.MuiOutlinedInput-root {
  border-radius: 10px;
  &:focus-within {
    border-radius: 10px;
    border: 1px solid #eece7c;
  }
}

.MuiCalendarPicker-root {
  .PrivatePickersFadeTransitionGroup-root .MuiTypography-caption {
    font-size: 0.875rem;
    font-weight: 500;
  }
  .PrivatePickersSlideTransition-root .MuiPickersDay-root {
    font-size: 0.75rem;
  }
}

.MuiDialogActions-root.MuiDialogActions-spacing {
  padding: 0px;
  justify-content: center;
}

.SnackbarItem-message {
  font-size: 1rem;
}

.MuiSelect-select {
  display: flex !important;
  padding: 0px !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 0px !important;
}

#menu- .MuiPaper-root {
  .MuiList-padding {
    max-height: 260px;
    overflow-y: auto;
    padding: 0px;
    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
      border: 1px solid #1a202c;
      background-color: #1a202c;
      border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb {
      background: #000;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #718096;
      height: 40px;
      border-radius: 100px;
    }
  }

  .MuiMenuItem-gutters {
    padding: 6px 0px !important;
    div {
      width: 100% !important;
    }
  }
  .data-none {
    display: none;
  }
}

.MuiBox-root {
  .css-1xuz8vi:focus {
    border-color: white;
  }
}

.MuiPaginationItem-root {
  color: white !important;
  border: transparent !important;
  &.Mui-selected {
    color: black !important;
    border-radius: 3px !important;
    background-color: white !important ;
  }
}

.MuiSvgIcon-root {
  &.MuiSelect-iconOutlined {
    color: white;
  }
}

.MuiContainer-roots {
  max-width: 1500px !important;
}

.MuiPaginationItem-previousNext {
  border: none !important;
  color: white !important;
}

.MuiList-root {
  margin-top: 10px;
}

.MuiPaper-root {
  &.MuiPaper-elevation {
    background-color: transparent;
  }
}

.MuiList-root {
  background-color: #fff;
}

.MuiAutocomplete-option {
  color: #fff;
}

.MuiAutocomplete-popper {
  z-index: 1300;
  position: absolute;
  background-color: #292929;
}

.MuiTab-root {
  font-size: 18px !important;
  font-weight: 700 !important;
}

.MuiTab-textColorPrimary {
  color: #919195 !important;
}

.MuiTypography-root,
.MuiTab-textColorPrimary.Mui-selected {
  color: #fff !important;
}
.MuiTooltip-arrow {
  color: #323243;
}
