.boxHeaderBorder {
  border-bottom: 1px solid #39393f;
  position: absolute;
  width: calc(100% - 28px);
  left: 14px;
  top: 115px;
}

.box_firstRow {
  height: 84px;
  display: flex;
  align-items: center;
}

.box_column {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.box_row {
  padding-top: 18px;
  height: 40px;
}

.column {
  text-align: center;
}
