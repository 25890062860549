.my-vip-level-component {
  .vip-image {
    position: relative;
    width: 100%;
    height: 230px;
    display: flex;
    justify-content: center;
    img {
      z-index: 1;
      height: fit-content;
    }
    &:before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0) 30%
      );
    }
  }
  .pending-rewards {
    @media (min-width: 1024px) {
    border-left: 1px solid #4f4f55;
    border-right: 1px solid #4f4f55;
    }
  }
}
